exports.components = {
  "component---src-pages-404-js": () => import("./../../../src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-home-banner-jsx": () => import("./../../../src/pages/HomeBanner.jsx" /* webpackChunkName: "component---src-pages-home-banner-jsx" */),
  "component---src-pages-index-js": () => import("./../../../src/pages/index.js" /* webpackChunkName: "component---src-pages-index-js" */),
  "component---src-pages-point-and-explain-jsx": () => import("./../../../src/pages/PointAndExplain.jsx" /* webpackChunkName: "component---src-pages-point-and-explain-jsx" */),
  "component---src-pages-row-section-jsx": () => import("./../../../src/pages/RowSection.jsx" /* webpackChunkName: "component---src-pages-row-section-jsx" */),
  "component---src-pages-section-jsx": () => import("./../../../src/pages/Section.jsx" /* webpackChunkName: "component---src-pages-section-jsx" */),
  "component---src-pages-time-line-jsx": () => import("./../../../src/pages/TimeLine.jsx" /* webpackChunkName: "component---src-pages-time-line-jsx" */)
}

